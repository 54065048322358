<template>
  <div>
    <h2>Préambule</h2>
    <v-card-text>
      <p>
        Ce texte a pour objet de définir les règles et les usages que doivent respecter les utilisateurs des serveurs de calcul et de stockage associés (nommés CIMENT dans la suite du présent document) ainsi que de la plateforme de *cloud computing* (nommée NOVA dans la suite du présent document) fournis par l'UMS GRICAD dans le cadre de son offre de service. 
        Ce texte n’est pas exhaustif en termes de responsabilités, de lois ou de déontologie ; 
        il ne se substitue notamment pas aux lois<sup>1</sup>, ni aux règles et usages en vigueur dans l’enseignement supérieur et la recherche et dans les établissements dont relèvent l'utilisateur et GRICAD<sup>2</sup>. 
      </p>
      <p>
        Ce document décrit les conditions générales d'utilisation communes aux services CIMENT et NOVA. 
        Pour les conditions particulières à chacun de ces services, ainsi que pour les conditions de traitement des données personnelles au travers du portail PERSEUS, merci de consulter les documents annexes joints à celui-ci.
      </p>
      <v-card-text>
        <v-divider
          class="mb-2"
          :style="style_divider_left"
        />
        <p>
          <sup>1</sup>
          Il est notamment rappelé que l’ensemble des utilisateurs de la grille de services, 
          quel que soit leur statut est soumis à la législation française en vigueur et notamment : 
          la loi du 29 juillet 1881 modifiée sur la liberté de la presse, 
          la loi n°78-17 du 6 janvier 1978 modifiée relative à l’informatique, aux fichiers et aux libertés, 
          la législation relative aux atteintes aux systèmes de traitement automatisé de données (art. L323-1 et suivants du code pénal), 
          la loi n° 94-665 du 4 août 1994 modifiée relative à l’emploi de la langue française- 
          la loi n° 2004-575 du 21 juin 2004 pour la confiance dans l’économie numérique, 
          les dispositions du code de propriété intellectuelle relative à la propriété littéraire et artistique
        </p>
        <p>
          <sup>2</sup>
          À ce titre, l’utilisateur est notamment renvoyé à la lecture de 
          <a href="http://www.dgdr.cnrs.fr/bo/2014/BOavrilV2.pdf">la charte informatique du CNRS</a> et 
          <a href="https://services-numeriques-personnels.univ-grenoble-alpes.fr/menu-principal/securite-protection-donnees/charte-d-usage-du-systeme-d-information/charte-d-usage-du-systeme-d-information-217870.kjsp">celle de l'UGA</a>.
        </p>
      </v-card-text>
    </v-card-text>


    <h2>Conditions d'accès</h2>
    <v-card-text>
      <p>
        Pour solliciter l'accès aux services GRICAD, l'utilisateur doit relever du monde académique ou être contractuellement autorisé.
        L'utilisateur devra créer un compte sur notre portail de gestion de comptes et de projets scientifiques de l'UMS GRICAD nommé PERSEUS<sup>3</sup> puis déposer ou rejoindre un projet scientifique qui sera validé scientifique ou techniquement par des rapporteurs issus de la communauté de recherche adéquate.
      </p>
      <p>
        Les services concernés sont destinés à être utilisés dans le seul cadre de l’activité professionnelle de l’utilisateur.
        La fourniture du service est strictement liée au projet pour lequel elle a été ouverte.
      </p>
      <p>
        La demande d’ouverture d’un compte et la création de projet est effectuée sur le portail PERSEUS.
        Pour permettre aux rapporteurs d'examiner votre demande, le responsable du projet est invité à nous donner le maximum d'informations concernant ses besoins : 
        une courte présentation du projet scientifique, les services sollicités, les ressources demandées a priori, le type de données concernées et les technologies mises en œuvre.
      </p>
      <p>
        L'ensemble des infrastructures soutenant les services de l'UMS sont hébergés dans les datacentres de l'Université Grenoble Alpes<sup>4</sup>.
      </p>
      <!-- les indices 3 et 4 -->
      <v-card-text>
        <v-divider
          class="mb-2"
          :style="style_divider_left"
        />
        <p>
          <sup>3</sup>
          <a href="https://perseus.univ-grenoble-alpes.fr/">https://perseus.univ-grenoble-alpes.fr/</a>
        </p>
        <p>
          <sup>4</sup>
          <i>Cloud privé</i> soumis à la réglementation française et européenne.
        </p>
      </v-card-text>
    </v-card-text>

    <h2>Durée du service</h2>
    <v-card-text>
      <p>
        Les conditions d’utilisation des services CIMENT et NOVA prennent effet dès l’ouverture d’un compte et du rattachement à un projet validé sur le portail PERSEUS.
        Ces services peuvent être arrêtés sur demande du responsable de projet ou de la personne mandatée par celui-ci pour prendre sa succession.
      </p>
      <p>
        La fourniture d’un service prend fin lors de la cessation de l’activité professionnelle qui l’a justifiée.
        Il est de la responsabilité de l’utilisateur d’informer l'UMS GRICAD de la cessation de son rattachement au projet ou de la fin du projet.
      </p>
      <p>
        L'UMS GRICAD demande aux responsables de projet un rapport annuel décrivant succinctement l'activité sur NOVA et/ou CIMENT liée à leur projet.
        Ce rapport conditionne le renouvellement du projet, et donc l'accès aux services concernés, pour une période d'un an.
      </p>
      <p>
        Les responsables des projets PERSEUS s'engagent également à tenir à jour la liste bibliographique relative à leurs activités sur CIMENT et NOVA.
      </p>
    </v-card-text>

    <h2>Suspension des services</h2>
    <v-card-text>
      <p>
        L'UMS GRICAD se réserve le droit de suspendre tout ou partie des services CIMENT et NOVA, dans les cas:
        <ul>
          <li>d’un risque pour la stabilité et/ou la sécurité des systèmes, des services, des données,</li>
          <li>d’une maintenance planifiée,</li>
          <li>d’une demande d'une autorité administrative ou judiciaire compétente ou du RSSI.</li>
          <li>en cas de force majeur, tel que décrit dans l'article 12-18 du code civil.</li>
        </ul>
      </p>
      <p>
        Cette suspension peut intervenir immédiatement et sans préavis en cas d’urgence ou de nécessité, ainsi qu’en cas d’utilisation illicite ou frauduleuse des services. <br>
        Dans la mesure du possible,  l'UMS veille à limiter la durée de la suspension lors de problème technique.
      </p>
      <p>
        L'UMS se réserve le droit de contacter le responsable du projet en cas :
        <ul>
          <li>du non respect manifeste des conditions générales d'utilisation</li>
          <li>du non respect des critères ayant permis l'accès au service</li>
          <li>d'absence de rapport annuel ou de bibliographie incomplète</li>
        </ul>
      </p>
      <p>
        En cas d'absence de réponse, au delà des 30 jours, l'UMS se réserve le droit de suspendre le service.
      </p>
    </v-card-text>

    <h2>Fin des services</h2>
    <v-card-text>
      <p>
        Si l'UMS GRICAD venait à mettre fin aux services concernés, elle s'engage à : 
        <ul>
          <li>Avertir l'utilisateur avec un préavis de 6 mois</li>
          <li>Mettre tout en oeuvre pour que l'utilisateur puisse récupérer ces données disponibles à l'émission de préavis jusqu'à la clôture du service</li>
          <li>Accompagner les utilisateurs, s'ils le souhaitent vers des stuctures proposant un service équivalent, si elles existent</li>
        </ul>
      </p>
    </v-card-text>
    
    <h2>Responsabilités utilisateur</h2>
    <v-card-text>
      <p>
        Les responsables des projets PERSEUS-NG sont garants de la conformité juridique de leurs données. 
        Ils veillent notamment à ce que la diffusion de contenus respecte les lois et règlements. 
        Ils engagent également leurs responsabilités quant à la possibilité de les communiquer. 
        Si les données sont soumises aux dispositions de la loi informatique et libertés, les responsables du projet doivent accomplir les formalités requises par la CNIL et veiller à un traitement des données conforme aux dispositions légales.
      </p>
      <p>
        Les responsables scientifiques et techniques du projet s'engagent à être en conformité avec le  règlement général de l’union européenne sur la protection des données (RGPD). 
        Le <a href="http://www.cil.cnrs.fr/CIL/spip.php?article3009">DPD du CNRS</a> propose un certains nombres de ressources pour cela. 
        Plus généralement, pour toutes questions à ce sujet, les utilisateurs doivent se tourner vers le DPD de la tutelle de rattachement de la direction de leur stucture.
      </p>
      <p>
        L’utilisateur est soumis aux contraintes des plans d’exploitation de l'UMS GRICAD et accepte les interruptions temporaires des services, liés notamment aux arrêts pour maintenance. 
        En cas d’interruption de services, l’utilisateur sera informé.
      </p>
    </v-card-text>
    
    <h2>Responsabilité GRICAD</h2>
    <v-card-text>
      <p>
        L'UMS GRICAD est responsable de l’exploitation et de la maintenance pendant toute la duràe de vie du matériel composant les infrastructures qui sous-tendent les servcies proposés.
      </p>
      <p>
        L'UMS GRICAD s'engage à assurer la gestion du cycle de fin de vie des composants matériels des infrastructures.
      </p>
      <p>
        Les services sont accessibles 7/7 - 24/24 hors incidents ou périodes de maintenances planifiée.
      </p>
      <p>
        L'UMS GRICAD s’engage à assurer une disponibilité maximale des différents services informatiques proposés à l’utilisateur. 
        Toutefois, elle ne peut être tenue pour responsable de leur indisponibilité.
      </p>
      <p>
        En cas d’interruption de service, l'UMS GRICAD mettra tout en œuvre pour rétablir le plus rapidement possible la fourniture du service sans toutefois que ce délai puisse être garanti d’aucune façon. 
        Elle ne sera en aucun cas responsable des préjudices que pourrait causer une interruption de service qu’elle soit de son fait ou due à une cause extérieure, sauf engagement contractuel contraire.
      </p>
      <p>
        L'UMS GRICAD se réserve le droit de prendre, sans préavis, toute mesure destinée à garantir la pérennité et la sécurité de ses services informatiques.
      </p>
      <p>
        En cas de maintenance planifiée, l'UMS GRICAD s'engage à avertir les utilisateurs avec un préavis d'au moins 10 jours ouvrés.
      </p>
      <p>
        L'UMS GRICAD n’opère aucune opération ou traitement sur les données en dehors de leur conservation à l’identique. 
        Il en est de même pour les applications apportées par l’utilisateur.
      </p>
    </v-card-text>

    <h2>Disponibilité</h2>
    <v-card-text>
      <p>
        Les services sont accessibles 7/7, 24H/24 hors incidents ou périodes de maintenance planifiées.
      </p>
    </v-card-text>

    <h2>Support</h2>
    <v-card-text>
      <p>
        L'UMS GRICAD s'engage à assister les utilisateurs en cas de problèmes ou de questions quant à l'utilisation des services. 
        Pour ce faire, elle met à disposition une adresse pour l'assistance des services : <a href="mailto:sos-gricad@univ-grenoble-alpes.fr">sos-gricad@univ-grenoble-alpes.fr</a>. 
        L'UMS s'engage à répondre à toute demande d'assistance dans un délai maximal de 10 jours ouvrés, hors période de fermeture administrative de l'UGA.
      </p>
    </v-card-text>

    <h2>Obligation de publicité</h2>
    <v-card-text>
      <p>
        L'UMS GRICAD se réserve le droit de publier la liste des projets pour la communication autour de ses différentes activités.
      </p>
      <p>
        L’utilisateur s’engage à citer dans tous les documents relatifs au projet, et sur les supports Web, le soutien apporté par l'UMS GRICAD. 
        Les ciattions peuvent prendre la forme présentée à cette adresse : <a href="https://gricad-doc.univ-grenoble-alpes.fr/#citations">https://gricad-doc.univ-grenoble-alpes.fr/#citations</a>.
      </p>
      <p>
        Les responsables des projets PERSEUS s'engagent à tenir à jour la liste bibliographique relatives à leurs activités sur CIMENT et NOVA.
      </p>
      <p>
        Afin d’accorder le meilleur suivi à chacun des projets, toute interaction avec l'UMS GRICAD devra, autant que possible s’effectuer par voie électronique via les adresses suivantes :
        <ul>
          <li>
            <a href="mailto:sos-gricad@univ-grenoble-alpes.fr">sos-gricad@univ-grenoble-alpes.fr</a> pour les demandes d’accès et pour la mise à jour des données du projet ainsi que tous problèmes ou questionnements relatifs à l'utisiation des plateformes CIMENT ou NOVA
          </li>
          <li>
            <a href="mailto:gricad-contact@univ-grenoble-alpes.fr">gricad-contact@univ-grenoble-alpes.fr</a> pour toute autre question
          </li>
        </ul>
      </p>
    </v-card-text>

    <h2>Application de l’accord</h2>
    <v-card-text>
      <p>
        L’utilisation des services proposés par l'UMS GRICAD équivaut à l’acceptation sans réserve de ces conditions générales d’utilisation. 
        En cas de non-respect des règles énoncées dans cette convention, le(s) service(s) proposé(s) par l'UMS GRICAD pourra(ont) être partiellement ou totalement suspendu(s).
      </p>
    </v-card-text>

    <v-divider class="my-10" />

    <h2>Annexe 1 : Les plateformes de calcul intensif et espaces de stockage associés</h2>
    <v-card-text>
      <h3>Préambule</h3>
      <p>
        Cette annexe complète les conditions générales d'utilisations des services GRICAD nécessitant une inscription sur le portail web PERSEUS. 
        Il détaille les conditions particulières pour l'utilisation de la plateforme de machines virtuelles NOVA.
      </p>

      <h3>Responsabilités utilisateur</h3>
      <p>
        Dans le cadre de l'utilisation des serveurs de calcul et de stockage asociés, l'utilisateur s'engage à lire <a href="https://gricad-doc.univ-grenoble-alpes.fr/hpc/">la documentation associée</a> avant toute utilisation. 
        En particulier, les utilisateurs s'engagent à respecter les bonnes pratiques d'utilisation du service, décrites dans la documentation, que ce soit pour l'utilisation des ressources de calcul ou des espaces de stockage associés.
      </p>
      <p>
        Si l'usage ne semble pas rentrer dans le cadre nominal d'utilisation du service, l'utilisateur s'engage à contacter en amont l'équipe support à l'adresse suivante : <a href="mailto:sos-calcul-gricad@univ-grenoble-alpes.fr">sos-calcul-gricad@univ-grenoble-alpes.fr</a>
      </p>

      <h3>Responsabilité GRICAD</h3>
      <p>
        L'UMS GRICAD s'engage à assurer le fonctionnement optimal des serveurs de calcul et de stockage associés ainsi qu'au partage équitable des ressources entre les utilisateurs, financeurs de l'infrastructure ou non.
      </p>
      <p>
        L'UMS GRICAD s'engage à mettre en oeuvre tous les moyens à sa disposition pour aider à la mise en place des environnements logiciels nécessaires aux utilisateurs.
      </p>
      <p>
        L'UMS GRICAD n'assure aucune pérennisation des données stockées sur l'infrastructure CIMENT. 
        Aucune sauvegarde n'est réalisée dans ce cadre.
      </p>
    </v-card-text>

    <v-divider class="my-10" />

    <h2>Annexe 2 : la plateforme NOVA</h2>
    <v-card-text>
      <h3>Préambule</h3>
      <p>
        Ce document complète les conditions générales d'utilisations des services GRICAD nécessitant une inscription sur le portail web PERSEUS. 
        Il détaille les conditions particulières pour l'utilisation de la plateforme de machines virtuelles NOVA.
      </p>

      <h3>Offre de service</h3>
      <p>
        Les services proposés par le service Nova :
        <ul>
          <li>approvisionnement de machines virtuels (VMs) à la demande,</li>
          <li>nombreux types disponibles (capacité CPU, RAM, disque…),</li>
          <li>OS disponibles (Centos, Debian, Ubuntu, NixOS…) multiples versions,</li>
          <li>quota et gestion par projet,</li>
          <li>gestion par interfaces Web, en ligne de commande (CLI) et API.</li>
        </ul>
      </p>
      <p>
        Les VMs à la demande ne sont pas pérennes et les volumes ne sont pas sauvegardés.
      </p>
      <p>
        Il n'y a pas d'exclusivité d'usage du matériel acheté par l'utilisateur; les ressources allouées sont toutefois d'un niveau similaire ou supérieur.
      </p>
      <p>
        Tout matériel est integré à la plateforme pour la durée de la garantie et de ses extensions (minimum 5 ans).
      </p>

      <h3>Responsabilités utilisateur</h3>
      <p>
        Dans le cadre de l'utilisation des machines virtuelles délivrées par la plateforme NOVA, l'utilisateur s'engage à lire <a href="https://gricad-doc.univ-grenoble-alpes.fr/nova/">la documentation associée</a> avant toute utilisation. 
        En particulier, l'utilisateur s'engage à libérer ses machines virtuelles dès qu'il ne les exploite plus, même temporairement.
      </p>
      <p>
        Le responsable du projet a l'entière responsabilité de l'exploitation logicielle et la sécurisation logicielle des machines virtuelles qui lui sont allouées dans le cadre de son projet. 
        En cas de manquement, l'UMS GRICAD ne saurait être tenue responsable.
      </p>

      <h3>Responsabilités GRICAD</h3>
      <p>
        L'UMS GRICAD s'engage à fournir des machines virtuelles correspondant aux ressources demandées par les projets validés, dans la limite de l'offre de service et de la disponibilité des ressources. 
      </p>

      <h3>Support</h3>
      <p>
        Pour toute demande de support vous pouvez envoyer un mail à l'adresse : 
        <a href="mailto:sos-nova-gricad@univ-grenoble-alpes.fr">sos-nova-gricad (at) univ-grenoble-alpes.fr</a>
        ou passer par notre <a href="https://sos-gricad.univ-grenoble-alpes.fr/Ticket/Create.html?Queue=9">gestionnaire de tickets</a>.
      </p>


    </v-card-text>

  </div>
</template>

<script>
  import { BaseTable } from '@/components'
  export default {
    components: {
      BaseTable,
    },
    data: () => ({
    }),
    computed: {
      style_divider_left () {
        return {
          width: `${this.$store.state.windowW / 3}px`,
        }
      },
    },
    methods: {
      isMobile () {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
          return true
        } else {
          return false
        }
      },
    },
  }
</script>
