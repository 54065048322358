export default {
  colors: {
    default: '#344675',
    primary: '#42b883',
    info: '#1d8cf8',
    danger: '#fd5d93',
    teal: '#00d6b4',
    green: '#00664b',
    indigo: '#4B0082',
    blue: '#0073e6',
    blue_dark: '#003366',
    red: '#990000',
    pink: '#ff4dd2',
    color_ok: '#4CAF50',
    color_not: '#F44336',
    primaryGradient: ['rgba(76, 211, 150, 0.1)', 'rgba(53, 183, 125, 0)', 'rgba(119,52,169,0)'],

    g_lightblue: '#7bcad8',
    g_middleblue: '#3ea7bf',
    g_darkblue: '#073c5d',
    g_red: '#eb4642',

    gricad_red_light4: '#f7bdbb',
    gricad_red_light3: '#f4a6a4',
    gricad_red_light2: '#f2908d',
    gricad_red_light1: '#ec645f',
    gricad_red: '#ec5752',
    gricad_red_dark1: '#e83730',
    gricad_red_dark2: '#b71b15',
    gricad_red_dark3: '#891410',
    gricad_red_dark4: '#5b0d0b',

    gricad_blue_light3: '#b0dfe8',
    gricad_blue_light2: '#9dd7e2',
    gricad_blue_light1: '#89cfdc',
    gricad_blue: '#7BCAD8',
    gricad_blue_dark1: '#62c0d0',
    gricad_blue_dark2: '#4eb8ca',
    gricad_blue_dark3: '#3bb0c4',
    gricad_blue_dark4: '#359eb1',
    gricad_blue_dark5: '#2f8d9d',

    gricad_darkblue_light3: '#0c6ba6',
    gricad_darkblue_light2: '#0b5c8e',
    gricad_darkblue_light1: '#094d77',
    gricad_darkblue: '#1c425e',
    gricad_darkblue_dark1: '#052e47',
    gricad_darkblue_dark2: '#031f30',
    gricad_darkblue_dark3: '#020f18',

    gricad_oceanblue_light4: '#88ccf7',
    gricad_oceanblue_light3: '#58b8f3',
    gricad_oceanblue_light2: '#28a4f0',
    gricad_oceanblue_light1: '#0f8ad7',
    gricad_oceanblue: '#0c6ba6', // = gricad_darkblue_light3
    gricad_oceanblue_dark1: '#0a5c8f',
    gricad_oceanblue_dark2: '#084d77',
    gricad_oceanblue_dark3: '#073d5f',
    gricad_oceanblue_dark4: '#052e48',

    primary_light3: '#a1ddc2',
    primary_light2: '#7cd0aa',
    primary_light1: '#56c292',
    primary_dark1: '#36966b',
    primary_dark2: '#297050',
    primary_dark3: '#1b4b35',

    card_bg_dark: 'rgba(5, 46, 72, 0.4)', // darkblue_dark1, opacity:0.4 // '#27293d',
    card_bg_light: 'rgba(128, 128, 128, 0.1)', // grey, opacity: 0.1 // 'rgba(136, 204, 247, 0.1)',

    dark_blue: '#1e1e2f',
  },
  sharedData: false,
}
