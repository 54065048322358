<template>
  <div>
    <h1 class="my-2">
      Information sur le traitement du portail Perseus-NG
    </h1>
    <p>
      Lorsque vous vous inscrivez sur le portail Perseus-NG ou que vous demandez l’ouverture d’un projet, les informations personnelles recueillies vous concernant vont faire l’objet d’un traitement par l’UMS GRICAD.
    </p>

    <h2>Objet du traitement :</h2>
    <v-card-text>
      <p>
        Les données à caractère personnel sont collectées et traitées avec comme finalité : la gestion des accès
        sécurisés aux services de GRICAD (calcul cloud notebook) et le suivi des projets associés à vos comptes.
      </p>
      <p>
        Les sous-finalités sont :
        <ul>
          <li>Gestion des utilisateurs, de la base des utilisateurs et des listes de diffusion ;</li>
          <li>Gestion de la base des projets et allocation des ressources ;</li>
          <li>Suivi des heures de calcul ou de traitement ;</li>
          <li>Suivi des projets.</li>
        </ul>
      </p>
    </v-card-text>

    <h2>La base légale du traitement est :</h2>
    <v-card-text>
      <p>
        Le traitement par l’UMS GRICAD est effectué sur la base de l’intérêt légitime à l’allocation des ressources de calcul de cloud et de notebook sur les plateformes de GRICAD.
      </p>
    </v-card-text>

    <h2>Les destinataires des données sont : </h2>
    <v-card-text>
      <p>
        <ul>
          <li>le personnel de GRICAD</li>
          <li>l’équipe support calcul GRICAD/CIMENT</li>
          <li>le groupe opérationnel du cloud NOVA GRICAD</li>
        </ul>
        Aucun transfert de données hors de l'Union européenne n'est réalisé.
      </p>
    </v-card-text>

    <h2>Conservation des données :</h2>
    <v-card-text>
      <p>
        Vos données personnelles sont conservées un an après la clôture des projets auxquels vous êtes rattachés.
        Les logs sont conservés un an.
      </p>
    </v-card-text>

    <h2>Vos droits :</h2>
    <v-card-text>
      <p>
        Vous disposez des droits suivants pour l’utilisation qui est faite de vos données : le droit d’opposition,
        d’accès, de rectification, d’effacement, le droit à une utilisation restreinte lorsque vos données ne sont pas
        nécessaires ou ne sont plus utiles, le droit à la portabilité : communiquer vos données à la personne de
        votre choix.
      </p>
      <p>
        Vous pouvez exercer ces droits en vous adressant à :
        <a href="gricad-rgpd@univ-grenoble-alpes.fr">gricad-rgpd@univ-grenoble-alpes.fr</a>
      </p>
      <p>
        UMS GRICAD Bâtiment IMAG 700 avenue centrale 38400 Saint-Martin d’Hères
        Vous pouvez contacter également le DPD CNRS de l’unité GRICAD : <br>
        DPD – 17 rue Notre Dame des Pauvres – 54519 – Vandœuvre lès Nancy Cedex
        dpd.demandes@cnrs.fr
      </p>
      <p>
        Si vous estimez, après nous avoir contactés, que vos droits Informatiques et Libertés ne sont pas respectés,
        vous avez la possibilité d’introduire une réclamation en ligne auprès de la CNIL ou par courrier postal. 
      </p>
    </v-card-text>

    <v-divider class="mb-4" />

    <v-row>
      <v-col cols="6">
        <p>
          UMS 3758 – Bâtiment IMAG – 700 avenue centrale 38400 St Martin d’Hères <br>
          Tél. : +33(0)4 57 42 18 54 <br>
          <a href="https://gricad.univ-grenoble-alpes.fr/">https://gricad.univ-grenoble-alpes.fr/</a>
        </p>
      </v-col>
      <v-col>
        <v-img
          src="@/assets/logo_uga_transparent.png"
          width="100px"
        />
      </v-col>
      <v-col>
        <v-img
          src="@/assets/logo_inp_transparent.png"
          width="100px"
        />
      </v-col>
      <v-col>
        <v-img
          src="@/assets/logo_cnrs_transparent.png"
          width="100px"
        />
      </v-col>
      <v-col>
        <v-img
          src="@/assets/logo_inria_transparent.png"
          width="100px"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
  export default {
    data: () => ({
    }),
    computed: {
    },
    methods: {
      isMobile () {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
          return true
        } else {
          return false
        }
      },
    },
  }
</script>
